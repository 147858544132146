<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartText :text="search.title">
			<template #extras>
				<div class="text-sm text-muted hstack gap-1">
					<div class="text-dark fw-bold" v-if="search?.user">{{ search.user.name }}</div>
					<div class="text-dark fw-bold" v-else-if="search?.group">{{ search.group.name }}</div>
					<div class="text-dark fw-bold" v-else>En toda la plataforma</div>
					<div>·</div>
					<div>{{ dateFormatted }}</div>
				</div>
			</template>
		</LayoutTableCommonPartText>
		<LayoutTableCommonPartRaw class="text-end">
			<div class="hstack gap-3 justify-content-end">
				<i class="bi" :class="{ 'bi-unlock': search.shareable, 'bi-lock': !search.shareable }"></i>
				<div class="avatar-group">
					<UserAvatarRounded v-for="user in search.users" :key="user.id" :user="user" size="xs" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" :data-bs-title="user.name" />
				</div>
			</div>
		</LayoutTableCommonPartRaw>
	</LayoutTableCommonRow>
</template>

<script>
	import { computed, onMounted } from 'vue';
	import { Tooltip } from 'bootstrap';
	import moment from 'moment';

	export default {
		props: {
			search: Object
		},
		setup(props) {
			const dateFormatted = computed(() => moment(props.search.created_at).format('DD/MM/YYYY HH:mm'));

			onMounted(() => {
				var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
				tooltipTriggerList.map(function (tooltipTriggerEl) { return new Tooltip(tooltipTriggerEl) });
			});

			return { dateFormatted };
		}
	}
</script>