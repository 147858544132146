<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<input type="text"
				ref="autocompleteInput"
				:id="inputId"
				v-model="address.title"
				class="form-control"
				:class="{'is-invalid': validation?.$error}"
				:placeholder="placeholder"
				v-focus="focus"
				autocomplete="off">
		<CommonMapMap ref="mapRef" :center="{ lat: address.geolocation.coordinates[0], lng: address.geolocation.coordinates[1] }" v-if="address.geolocation?.coordinates">
			<CommonMapMarker :position="{ lat: address.geolocation.coordinates[0], lng: address.geolocation.coordinates[1] }" v-if="mapRef?.mapRef.ready" />
		</CommonMapMap>
	</LayoutFormInputGeneral>
</template>

<script>
	import { ref, onMounted, inject } from 'vue';
	import { Loader } from '@googlemaps/js-api-loader';

	export default {
		emits: ['update:modelValue'],
		props: {
			modelValue: {
				type: Object,
				default: {
					title: null,
					geolocation: {}
				}
			},
			title: String,
			subtitle: String,
			placeholder: String,
			validation: Object,
			focus: {
				type: Boolean,
				default: false
			}
		},
		setup(props, { emit }) {
			const $env = inject('$env');
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;
			const autocomplete = ref(null);
			const autocompleteInput = ref(null);
			const mapRef = ref(null);
			const address = ref(props.modelValue);

			const loader = new Loader({
				apiKey: $env.GOOGLE_API_KEY,
				libraries: ['places']
			});

			const onPlaceChanged = () => {
				const place = autocomplete.value.getPlace();

				if(place.geometry) {
					address.value.title = place.formatted_address;
					address.value.geolocation = {
						type: 'Point',
						coordinates: [
							place.geometry.location.lat(),
							place.geometry.location.lng()
						]
					};

					emit('update:modelValue', address.value);
				}
			};

			onMounted(() => {
				loader.load().then(() => {
					// Inicializar el Autocomplete
					autocomplete.value = new google.maps.places.Autocomplete(autocompleteInput.value);
					autocomplete.value.addListener('place_changed', onPlaceChanged);
				});
			});

			return { inputId, autocompleteInput, mapRef, address };
		}
	}
</script>