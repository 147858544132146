<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection :text="group?.name ?? '...'" :breadcrumb="true" />
			</template>
			<template #actions>
				<div class="hstack gap-2 justify-content-start justify-content-lg-end">
					<router-link :to="{ name: 'group.search.index', params: { id: group?.id }}" class="btn btn-sm btn-dark rounded-pill">Buscar en el grupo</router-link>
					<AccountGroupCommonMenu :group="group" />
				</div>
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-5 pb-10 pb-0 vstack gap-10" v-if="!loading">
			<AccountGroupSearchList :group="group" />
		</main>
	</LayoutMain>
</template>

<script>
	import { ref, computed, onBeforeMount } from 'vue';
	import router from '@/router';
	import { useRoute } from 'vue-router';
	import moment from 'moment';
	import composableForm from '@/composables/form';
	import AccountGroupRepository from '@/repositories/Account/GroupRepository';

	export default {
		setup() {
			const { loading, catchError } = composableForm();
			const route = useRoute();
			const group = ref({});
			const createdAt = computed(() => moment(group.value?.created_at).format('DD/MM/YYYY'));

			onBeforeMount(async () => {
				await AccountGroupRepository.show(route.params.id).then((response) => {
					group.value = response.data;
				}).catch((e) => {
					catchError(e, true);
					router.push({ name: `account.group.index` });
				});

				loading.value = false;
			});

			return { route, loading, group, createdAt };
		}
	}
</script>