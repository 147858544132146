<template>
	<td class="p-0">
		<a href="javascript:void(0);" class="stretched-link" @click="select"></a>
	</td>
</template>

<script>
	export default {
		emits: ['select'],
		setup(props, { emit }) {
			const select = () => emit('select');

			return { select };
		}
	}
</script>

<style scoped>
	td {
		width: 0px !important;
	}
</style>