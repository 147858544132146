<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartImage :src="image.file_url" size="lg" />
		<LayoutTableCommonPartRaw class="py-4 text-break lh-1">
			<div class="text-sm text-muted lh-sm">{{ dateDiff }}</div>
			<a href="javascript:void(0);" class="link-dark text-md" @click="select">{{ publication.title ?? 'Sin título' }}</a>
			<PublicationPartBadge :publication="publication" :scrollable="false" />
			<div class="d-flex" v-if="publication.priority"><PublicationPartPriority :priority="publication.priority" /></div>
		</LayoutTableCommonPartRaw>
	</LayoutTableCommonRow>
</template>

<script>
	import _ from 'lodash';
	import moment from 'moment';

	export default {
		emits: ['select'],
		props: {
			publication: Object
		},
		setup(props, { emit }) {
			const image = _.head(_.filter(props.publication.medias, { type: 'image' }));
			const dateDiff = moment(props.publication.created_at).fromNow();
			const select = () => emit('select', props.publication);

			return { image, dateDiff, select };
		}
	}
</script>

<style scoped>
	.dropdown-menu {
		width: 180px;
	}
</style>