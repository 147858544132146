<template>
	<div class="text-sm text-warning lh-sm"><i class="bi bi-info-circle me-1"></i><span v-html="info"></span></div>
</template>

<script>
	export default {
		props: {
			info: String
		}
	}
</script>