<template>
	<LayoutModalStandard ref="modal" :title="publication?.title" :subtitle="publication?.publicable?.name" id="publication-modal-show">
		<template #body v-if="publication">
			<form class="vstack gap-5" @submit.prevent="submit" autocomplete="off">
				<div class="d-flex gap-2 scrollable-x mx-n5 px-5">
					<!--<div v-for="media in publication?.medias" v-bind:key="media.id" class="avatar avatar-xl avatar-background border border-2 border-dark rounded-3">-->
					<div v-for="(media, index) in publication?.medias" v-bind:key="media.id" class="position-relative avatar avatar-xl rounded-4 border border-2 border-dark cursor-pointer" @click="showImage(index)">
						<img :src="media.file_url" class="object-cover position-absolute top-50 start-50 translate-middle rounded-0" />
					</div>
				</div>
				<PublicationPartBadge :publication="publication" :scrollable="false" color="dark" />
				<PublicationPartPriorityComplete :priority="publication.priority" :description="publication.priority_description" v-if="publication?.priority" />
				<LayoutFormInputGeneral :title="publication?.address?.title" v-if="publication?.address">
					<CommonMapMap :center="{ lat: publication?.address.geolocation.coordinates[0], lng: publication?.address.geolocation.coordinates[1] }">
						<CommonMapMarker :position="{ lat: publication?.address.geolocation.coordinates[0], lng: publication?.address.geolocation.coordinates[1] }" />
					</CommonMapMap>
				</LayoutFormInputGeneral>
				<PublicationPartUsers :users="publication.users" />
				<PublicationPartStock :stock="publication.extras.stock" v-if="publication.extras?.stock" />
				<PublicationPartExtras :extras="publication.extras" v-if="publication?.extras" />
				<LayoutFormInputGeneral title="Descripción" v-if="publication?.description">
					<div class="text-md lh-xs" v-html="publication.description"></div>
				</LayoutFormInputGeneral>
				<PublicationPartDecisions :users="publication.users_decisions" v-if="filtered && publication?.users_decisions.length > 0" />
				<CommonFormInputTextarea v-model="form.comment" title="Comentarios" placeholder="Escribe aquí los comentarios que creas convenientes..." :rows="3" v-if="canEdit" />
			</form>
		</template>
		<template #footer v-if="publication">
			<template v-if="canEdit">
				<button type="button" class="btn btn-outline-danger rounded-pill me-auto" :disabled="submitting" @click="decision(false);" v-if="publication.pivot.like">Ya no me gusta</button>
				<button type="button" class="btn btn-outline-success rounded-pill me-auto" :disabled="submitting" @click="decision(true);" v-else>Me gusta</button>
				<button type="button" class="btn btn-primary rounded-pill" :disabled="submitting" @click="submit">Guardar</button>
			</template>
			<template v-else>
				<button type="button" class="btn btn-neutral rounded-pill" data-bs-dismiss="modal">Cerrar</button>
			</template>
		</template>
	</LayoutModalStandard>

	<vue-easy-lightbox :visible="modalImages" :imgs="imagesSrc" :index="modalImagesPosition" :rotate-disabled="true" :pinch-disabled="true" :move-disabled="true" @hide="modalImages = false">
		<template v-slot:toolbar="{ toolbarMethods }">
			<div class="btn-group position-absolute bottom-0 start-0 mb-4 ms-4" role="group">
				<button type="button" class="btn btn-outline-light btn-square rounded-start-pill lh-1" @click="toolbarMethods.zoomOut"><i class="bi bi-zoom-out text-lg"></i></button>
				<button type="button" class="btn btn-outline-light btn-square lh-1" @click="toolbarMethods.rotateRight"><i class="bi bi-arrow-clockwise text-lg"></i></button>
				<button type="button" class="btn btn-outline-light btn-square rounded-end-pill lh-1" @click="toolbarMethods.zoomIn"><i class="bi bi-zoom-in text-lg"></i></button>
			</div>
		</template>
		<template v-slot:prev-btn="{ prev }">
			<div class="position-absolute bottom-0 end-0 mb-4 me-32" role="toolbar">
				<button type="button" class="btn btn-outline-light btn-square rounded-pill lh-1" @click="prev"><i class="bi bi-chevron-left text-lg"></i></button>
			</div>
		</template>
		<template v-slot:close-btn="{ close }">
			<div class="position-absolute bottom-0 end-0 mb-4 me-18" role="toolbar">
				<button type="button" id="vue-easy-lightbox-close" class="btn btn-outline-light btn-square rounded-pill lh-1" @click="close"><i class="bi bi-x-lg text-lg"></i></button>
			</div>
		</template>
		<template v-slot:next-btn="{ next }">
			<div class="position-absolute bottom-0 end-0 mb-4 me-4" role="toolbar">
				<button type="button" class="btn btn-outline-light btn-square rounded-pill lh-1" @click="next"><i class="bi bi-chevron-right text-lg"></i></button>
			</div>
		</template>
	</vue-easy-lightbox>
</template>

<script>
	import { ref, reactive, computed, watch, onUnmounted } from 'vue';
	import store from '@/store';
	import _ from 'lodash';
	import VueEasyLightbox from 'vue-easy-lightbox';
	import composableForm from '@/composables/form';
	import SearchRepository from '@/repositories/SearchRepository';

	export default {
		components: { VueEasyLightbox },
		emits: ['success'],
		props: {
			search: Object,
			filters: Object,
			publication: Object,
			filtered: Boolean
		},
		setup(props, { emit }) {
			const { submitting, catchError } = composableForm();
			const user = computed(() => store.getters['auth/user']);
			const canEdit = computed(() => (props.filtered && props.filters?.user_id == user.value.id));
			const modal = ref(null);

			const modalImages = ref(false);
			const modalImagesPosition = ref(0);
			const imagesSrc = computed(() => _.map(props.publication?.medias ?? [], 'file_url'));

			const showImage = (index) => {
				modalImagesPosition.value = index;
				modalImages.value = true;
			}

			const form = reactive({
				comment: null
			});

			watch(() => props.publication, (newValue) => {
				form.comment = newValue?.pivot?.comment;
			});

			const submit = async () => {
				if(!canEdit.value) return;
				if(submitting.value) return;
				if(form.comment == props.publication.pivot.comment) return modal.value.modal.hide();
				submitting.value = true;

				await SearchRepository.decision(props.search.uuid, { publication_id: props.publication.id, comment: form.comment }).then((response) => {
					emit('success');
					modal.value.modal.hide();
				}).catch(catchError);

				submitting.value = false;
			}

			const decision = async (like) => {
				if(submitting.value) return;
				submitting.value = true;

				await SearchRepository.decision(props.search.uuid, { publication_id: props.publication.id, like: like }).then((response) => {
					emit('success');
					modal.value.modal.hide();
				}).catch(catchError);

				submitting.value = false;
			}

			const show = () => modal.value.modal.show();

			onUnmounted(() => {
				document.body.style.overflowY = '';
			});

			return { canEdit, modal, modalImages, modalImagesPosition, imagesSrc, showImage, form, submit, submitting, decision, show };
		}
	}
</script>