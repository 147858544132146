<template>
	<td class="py-4 text-break lh-1" :colspan="colspan">
		<div class="text-sm text-muted lh-sm" v-if="header">{{ header }}</div>
		<div class="text-md">{{ text }}</div>
		<div class="text-sm" v-if="subtext">{{ subtext }}</div>
		<div class="vstack gap-1" v-if="$slots.extras">
			<slot name="extras" />
		</div>
	</td>
</template>

<script>
	export default {
		props: {
			text: String,
			header: String,
			subtext: String,
			colspan: {
				type: Number,
				defaul: 1
			}
		}
	}
</script>