<template>
	<div ref="card" class="card rounded-0 h-100 w-100 images-place z-1 border-0" :style="cardStyle">
		<div class="hstack gap-2 justify-content-end px-4 py-4 w-100 z-1 position-absolute position-relative">
			<div class="hstack gap-2 me-auto">
				<PublicationPartPriority :priority="publication.priority" iconClass="text-md" v-if="publication.priority" />
				<div class="spinner-grow spinner-grow-sm" role="status" v-if="!backgroundDisplay && backgroundLoadingDisplay">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>
			<div class="rounded-pill ps-3 pe-1 py-1 lh-1 hstack gap-2 border border-2 shadow fw-bold" :style="infoStyle" @click.stop>
				<template v-if="publication.currency && (publication.amount || publication.amount_sale)">
					<div class="text-danger" v-if="publication.amount_sale">- {{ Math.ceil((publication.amount - publication.amount_sale) * 100 / publication.amount) }}%</div>
					<div>{{ vueNumberFormat((publication.amount_sale ?? publication.amount), { suffix: ` ${publication.currency}`, precision: 0 }) }}</div>
				</template>
				<div v-else>Sin precio</div>
				<a href="javascript:void(0);" class="badge badge-count rounded-circle stretched-link" @click="detail" v-if="publication.like === true && publication.is_super === true"><i class="bi bi-heart-fill text-sm text-white"></i></a>
				<a href="javascript:void(0);" class="badge badge-count rounded-circle stretched-link" @click="detail" v-else-if="publication.like === true && publication.is_super === false"><i class="bi bi-heart-fill text-sm text-success"></i></a>
				<a href="javascript:void(0);" class="badge badge-count rounded-circle stretched-link" @click="detail" v-else-if="publication.like === false"><i class="bi bi-x-lg text-sm text-danger"></i></a>
				<a href="javascript:void(0);" class="badge badge-count bg-dark rounded-circle stretched-link" @click="detail" v-else><i class="bi bi-info-lg text-white"></i></a>
			</div>
		</div>
		<div class="card-body p-0 d-flex flex-column body-main" @pointerdown="startDragging" @pointermove="dragging" @pointerup="stopDragging" @pointerleave="stopDragging">
			<div class="flex-fill" @click.stop="handleImageClick">
				<div class="progress rounded-0" v-if="imageCount > 0">
					<div class="progress-bar bg-dark" style="width: 25%" :style="{ width: `${Math.round(imageIndex * 100 / imageCount)}%` }"></div>
				</div>
			</div>
			<div class="background-description text-white">
				<div class="p-4">
					<div class="vstack lh-sm text-shadow position-relative text-limit">
						<a href="javascript:void(0);" class="link-light text-break text-truncate stretched-link text-xl" @click="detail">{{ publication.title }}</a>
						<div class="text-truncate" v-if="publication.address">{{ publication.address.title }}</div>
						<PublicationPartCreator :users="publication.users" />
					</div>
					<PublicationPartBadge :publication="publication" :hidden="['amount']" :selectable="true" @select="detail" color="white" class="pt-2" @pointerdown.stop />
				</div>
			</div>
		</div>
	</div>
	<div class="position-absolute w-100 h-100 z-0">
		<div class="hstack align-items-center justify-content-between mt-5">
			<div style="padding: 10px;" :style="{ width: `${draggingSize}px` }">
				<div ref="circleLike" class="square border border-5 border-success text-success rounded-circle p-3 pb-1 d-flex justify-content-center align-items-center" style="opacity: .2; transition: all 300ms;">
					<i class="bi bi-heart-fill" style="font-size: 50px;"></i>
				</div>
			</div>
			<div style="padding: 10px;" :style="{ width: `${draggingSize}px` }">
				<div ref="circleReject" class="square border border-5 border-danger text-danger rounded-circle p-3 pb-2 d-flex justify-content-center align-items-center" style="opacity: .2; transition: all 300ms;">
					<i class="bi bi-x-lg" style="font-size: 50px;"></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, reactive, computed, watch, onMounted } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['detail', 'decision', 'setViewed'],
		props: {
			publication: Object,
			isActive: Boolean
		},
		setup(props, { emit }) {
			const draggingSize = 130;
			const transition = 100;
			const card = ref(null);
			const isDragging = ref(false);
			const isDeciding = ref(null);
			const circleLike = ref(null);
			const circleReject = ref(null);
			const wrapperAspectRatio = ref(null);
			const imageCount = props.publication.medias.length;
			const imageIndex = ref(1);
			const imageCurrent = computed(() => {
				if((imageIndex.value - 1) in props.publication.medias) return props.publication.medias[(imageIndex.value - 1)];
				return null;
			});
			const imageCurrentBackgroundColor = computed(() => {
				if(imageCurrent.value) return imageCurrent.value.info?.color ?? '#FFFFFF';
				return null;
			});
			const imageCurrentBackgroundSize = computed(() => {
				if(imageCurrent.value.info) {
					let aspectRatio = imageCurrent.value.info.size.width / imageCurrent.value.info.size.height,
						diff = wrapperAspectRatio.value - aspectRatio;

					return (Math.abs(diff) > 0.3) ? 'contain' : 'cover';
				}else return 'cover';
			});
			const infoStyle = computed(() => {
				if(props.publication.like === true && props.publication.is_super === true) return {
					color: 'rgba(var(--x-white-rgb), 1) !important',
					borderColor: 'rgba(var(--x-white-rgb), 1) !important',
					backgroundColor: 'rgba(var(--x-success-rgb), 1) !important'
				};
				else if(props.publication.like === true && props.publication.is_super === false) return {
					borderColor: 'rgba(var(--x-success-rgb), 1) !important',
					backgroundColor: '#caf2e3'
				};
				else if(props.publication.like === false) return {
					borderColor: 'rgba(var(--x-danger-rgb), 1) !important',
					backgroundColor: '#fdd3e3'
				};
				else return {
					backgroundColor: '#ffffff'
				};
			});

			const detail = () => {
				emit('detail', props.publication);
			}

			const handleImageClick = (event) => {
				const elementRect = event.srcElement.getBoundingClientRect();
				const clickX = event.clientX - elementRect.left;
				const elementCenter = elementRect.width / 2;

				if(clickX < elementCenter) {
					if(imageIndex.value > 1) imageIndex.value--;
				}else{
					if(imageIndex.value < imageCount) imageIndex.value++;
				}
			};

			const cacheImages = () => {
				var cache = [];
				if(props.isActive) cache = _.clone(props.publication.medias);
				else cache.push(props.publication.medias[0]);

				_.forEach(cache, function(media) {
					const img = new Image();
					img.src = media.file_url;
				});
			}

			const checkActive = () => {
				if(props.isActive) {
					emit('setViewed', props.publication.id);
				}
			}

			watch(() => props.isActive, () => {
				checkActive();
				cacheImages();
			});

			onMounted(() => {
				wrapperAspectRatio.value = card.value.clientWidth / card.value.clientHeight;

				checkActive();
				cacheImages();
				backgroundLoad();
			});

			const startX = ref(0);
			const startY = ref(0);
			const moveX = ref(0);
			const moveY = ref(0);

			const setTransform = (x) => {
				if(x > draggingSize) x = draggingSize;
				else if(x < (draggingSize * -1)) x = draggingSize * -1;

				//if(Math.abs(x) < 100) x = 0;
				card.value.style.transform = `translate3d(${x}px, 0px, 0)`;

				var opacity = (isDeciding.value && Math.abs(moveX.value) >= draggingSize) ? 1 : .2;
				circleLike.value.style.opacity = opacity;
				circleReject.value.style.opacity = opacity;
			}

			const startDragging = ({ clientX, clientY }) => {
				if(props.isActive) {
					isDragging.value = true;
					card.value.style.transition = 'transform 0ms';

					startX.value = clientX;
					startY.value = clientY;
				}
			}

			const dragging = ({ clientX, clientY }) => {
				if(isDragging.value) {
					moveX.value = clientX - startX.value;
					moveY.value = clientY - startY.value;

					if(isDeciding.value == null && (Math.abs(moveX.value) > 10 || Math.abs(moveY.value) > 10)) {
						isDeciding.value = (Math.abs(moveX.value) > Math.abs(moveY.value));
					}

					if(isDeciding.value) setTransform(moveX.value);
				}
			}

			const stopDragging = () => {
				if(isDragging.value) {
					card.value.style.transition = `transform ${transition}ms`;

					if(isDeciding.value && Math.abs(moveX.value) >= draggingSize) emit('decision', (moveX.value > 0));
					setTransform(0);

					isDragging.value = false;
					isDeciding.value = null;
				}
			}

			const backgroundDisplay = ref(false);
			const backgroundLoadingDisplay = ref(false);
			const backgroundLoad = () => {
				backgroundDisplay.value = false;
				backgroundLoadingDisplay.value = false;

				let loadImage = new Image();
				loadImage.src = imageCurrent.value.file_url;
				loadImage.onload = () => backgroundDisplay.value = true;

				setTimeout(() => {
					backgroundLoadingDisplay.value = true;
				}, 500);
			}

			watch(imageCurrent, backgroundLoad);

			const cardStyle = computed(() => {
				if(backgroundDisplay.value) return {
						backgroundImage: `url(${imageCurrent.value.file_url})`,
						backgroundColor: imageCurrentBackgroundColor.value,
						backgroundSize: imageCurrentBackgroundSize.value,
						transition: `transform ${transition}ms`
					};
			});

			return { card, cardStyle, draggingSize, circleLike, circleReject, imageCount, imageIndex, infoStyle, detail, handleImageClick, startDragging, dragging, stopDragging, backgroundDisplay, backgroundLoadingDisplay };
		}
	}
</script>

<style scoped>
	.card {
		position: absolute;
		user-select: none;
		touch-action: none;
		overflow: hidden;
		cursor: pointer;
	}

	.background-description {
		background: linear-gradient(to top, #00000099, #00000004);
	}

	.images-place {
		background-position: center;
		background-repeat: no-repeat;
	}

	.progress {
		height: 4px;
		background-color: #a9a9a9;
	}
</style>