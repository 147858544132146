<template>
	<div class="row g-2 py-1 h-100">
		<div class="col-4 mt-n4 vstack gap-2">
			<UserIndexImagesShow v-for="(url, index) in (columns[0] ?? [])" v-bind:key="index" :url="url" />
		</div>
		<div class="col-4 pt-1 vstack gap-2">
			<UserIndexImagesShow v-for="(url, index) in (columns[1] ?? [])" v-bind:key="index" :url="url" />
		</div>
		<div class="col-4 mt-n10 vstack gap-2">
			<UserIndexImagesShow v-for="(url, index) in (columns[2] ?? [])" v-bind:key="index" :url="url" />
		</div>
	</div>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import _ from 'lodash';

	export default {
		props: {
			images: Array
		},
		setup(props) {
			const urls = ref([]);
			const columns = computed(() => _.chunk(urls.value, 7));

			const preloadImage = (url) => {
				return new Promise((resolve, reject) => {
					const img = new Image();
					img.src = url;
					img.onload = () => resolve(url);
					img.onerror = reject;
				});
			}

			onMounted(() => {
				_.forEach(_.shuffle(props.images), async (url) => {
					await preloadImage(url).then(() => {
						urls.value.push(url);
					});
				});
			});

			return { columns };
		}
	}
</script>