<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartImage :src="user.avatar_url" size="md" />
		<LayoutTableCommonPartText :text="user.name">
			<template #extras>
				<div class="text-sm text-muted hstack gap-1">
					<div class="text-dark fw-bold">{{ user.email }}</div>
					<div>·</div>
					<div>desde {{ dateFormatted }}</div>
				</div>
			</template>
		</LayoutTableCommonPartText>
		<LayoutTableCommonPartMenu>
			<template #options>
				<router-link :to="{ name: 'admin.user.form', params: { id: user.id }}" class="dropdown-item rounded-0">Editar</router-link>
				<a href="javascript:void(0)" class="dropdown-item rounded-0" @click="remove">Eliminar</a>
			</template>
		</LayoutTableCommonPartMenu>
	</LayoutTableCommonRow>
</template>

<script>
	import { computed } from 'vue';
	import moment from 'moment';

	export default {
		emits: ['remove'],
		props: {
			user: Object
		},
		setup(props, { emit }) {
			const remove = () => emit('remove', props.user);
			const dateFormatted = computed(() => moment(props.user.created_at).format('DD/MM/YYYY'));

			return { remove, dateFormatted };
		}
	}
</script>