<template>
	<div class="hstack gap-2 align-items-center position-relative">
		<UserCommonImage :src="user?.avatar_url" size="xs" class="mx-auto" />
		<div class="flex-fill lh-xs text-limit">
			<a href="javascript:void(0);" class="text-dark text-sm text-break text-truncate stretched-link" @click="select">{{ user?.name }}</a>
			<div class="text-muted text-truncate" v-if="subtitle">{{ subtitle }}</div>
		</div>
	</div>
</template>

<script>
	import { getCurrentInstance } from 'vue';

	export default {
		props: {
			user: Object,
			subtitle: {
				type: String,
				default: null
			},
			contact: {
				type: Boolean,
				default: false
			}
		},
		setup(props) {
			const instance = getCurrentInstance();

			const select = () => {
				if(props.contact) instance.proxy.$contactUser(props.user);
			}

			return { select };
		}
	}
</script>