<template>
	<nav class="navbar navbar-expand-lg navbar-light px-0 z-3" :class="{ 'fixed-top': fixed, 'header-shadow': (shadow == 'ever') }">
		<div class="container-md hstack align-items-center justify-content-between">
			<!-- Logo -->
			<router-link :to="{ name: 'home' }">
				<img src="@/assets/img/logo/complete.png" class="h-rem-8">
			</router-link>
			<div class="hstack align-items-center gap-3">
				<a href="javascript:void(0);" class="btn btn-sm py-2 border shadow-2-hover bg-white rounded-pill" @click="redirectBack" v-if="hasRedirectBack">
					<i class="bi bi-chevron-left pe-2"></i>Volver
				</a>
				<div class="hstack gap-3">
					<UserCardMenu :user="user" v-if="hasUser" />
					<UserCardMenuEmpty v-else />
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';
	import { useRoute } from 'vue-router';
	import composableRedirect from '@/composables/redirect';

	export default {
		props: {
			fixed: {
				type: Boolean,
				default: false
			},
			shadow: {
				type: String,
				default: 'none'
			}
		},
		setup() {
			const { hasRedirectBack, redirectBack, redirectSearch } = composableRedirect();
			const route = useRoute();
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);

			return { hasRedirectBack, redirectBack, redirectSearch, route, hasUser, user };
		}
	}
</script>

<style scoped>
	.navbar-nav > .nav-item > .nav-link, .navbar-nav > .nav-link {
		background-color: inherit;
	}

	.navbar-nav > .nav-item > .nav-link:hover, .navbar-nav > .nav-item > .nav-link:focus, .navbar-nav > .nav-link:hover, .navbar-nav > .nav-link:focus {
		background-color: inherit;
	}

	.item-search {
		min-width: 240px;
	}

	.header-shadow {
		box-shadow: 0 2px 4px 0 rgba(32, 33, 36, .3);
	}

	.fixed-top {
		background-color: rgb(255 255 255 / 90%);
		backdrop-filter: blur(3px);
	}
</style>