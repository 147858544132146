<template>
	<div class="avatar bg-white" :class="classes">
		<img :src="imageSrc" class="object-cover" alt="Avatar">
	</div>
</template>

<script>
	import { computed } from 'vue';
	import assetsImgIconsNoImage from '@/assets/img/icons/no-image.jpg';

	export default {
		props: {
			src: String,
			imageClass: String,
			size: {
				type: String,
				default: 'md'
			},
			rounded: {
				type: String,
				default: 'circle'
			},
			border: {
				type: Boolean,
				default: false
			}
		},
		setup(props) {
			const classes = [
				`avatar-${props.size}`,
				`rounded-${props.rounded}`,
				props.imageClass,
				(props.border) ? 'border' : null
			];

			const imageSrc = computed(() => {
				if(props.src) return props.src;
				else return assetsImgIconsNoImage;
			});

			return { classes, imageSrc };
		}
	}
</script>