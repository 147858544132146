<template>
	<div ref="card" class="card border-0 rounded-0 h-100 images-place w-100 z-1 p-8 px-md-20 d-flex align-items-center">
		<div class="my-auto">
			<div class="vstack gap-5" v-if="count > 0">
				<h1 class="h1 lh-xs ls-tighter font-bolder">
					¿Te gustó lo que encontraste?
					<span class="d-block text-primary">¡Envia los resultados!</span>
				</h1>
				<p class="lh-sm text-lg font-regular">Ahora podés compartir tus búsquedas con otras personas, o simplemente enviar tu listado a quien vos quieras, enviándolo por whatsapp, email o cualquier otro medio.</p>
				<div class="d-flex flex-column flex-md-row gap-3">
					<div v-if="!search.pivot?.owner">
						<button type="button" @click="finish" class="btn btn-md btn-warning font-bold rounded-pill" :disabled="finishSend"><i class="bi bi-flag me-3"></i>Finalizar selección</button>
					</div>
					<div>
						<router-link :to="{ name: 'account.search.filtered', params: { uuid: route.params?.uuid }}" class="btn btn-md btn-dark font-bold rounded-pill">Ver publicaciones</router-link>
					</div>
					<div>
						<button type="button" @click="share" class="btn btn-md btn-primary font-bold rounded-pill"><i class="bi bi-share me-3"></i>Compartir</button>
					</div>
					<div v-if="search.pivot?.owner && search.group_id">
						<button type="button" @click="publish" class="btn btn-md btn-secondary font-bold rounded-pill"><i class="bi bi-megaphone me-3"></i>Publicar búsqueda</button>
					</div>
				</div>
			</div>
			<div class="vstack gap-5" v-else>
				<h1 class="h1 lh-xs ls-tighter font-bolder">
					<span class="d-block text-primary">¡Ups!</span> Parece que no tuviste suerte.
				</h1>
				<p class="lh-sm text-lg font-regular">¡No hay problema! podés intentar con otra búsqueda :)</p>
				<div class="d-flex flex-column flex-md-row gap-3">
					<div v-if="!search.pivot?.owner">
						<button type="button" @click="finish" class="btn btn-md btn-warning font-bold rounded-pill" :disabled="finishSend"><i class="bi bi-flag me-3"></i>Finalizar selección</button>
					</div>
					<div>
						<button type="button" class="btn btn-md btn-dark font-bold rounded-pill" @click="redirectSearch">Nueva búsqueda</button>
					</div>
					<div v-if="search.pivot?.owner && search.group_id">
						<button type="button" @click="publish" class="btn btn-md btn-secondary font-bold rounded-pill"><i class="bi bi-megaphone me-3"></i>Publicar búsqueda</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref } from 'vue';
	import { useRoute } from 'vue-router';
	import composableRedirect from '@/composables/redirect';

	export default {
		emits: ['share', 'publish', 'finish'],
		props: {
			search: Object,
			count: Number
		},
		setup(props, { emit }) {
			const { redirectSearch } = composableRedirect();
			const route = useRoute();
			const finishSend = ref(false);

			const share = async (type) => emit('share');
			const publish = async (type) => emit('publish');
			const finish = async (type) => {
				emit('finish');
				finishSend.value = true;
			}

			return { route, share, publish, finish, finishSend, redirectSearch };
		}
	}
</script>