<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection text="SuperLikes de la publicación" :breadcrumb="true" />
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-5 pb-10 pb-0 vstack gap-10">
			<PublicationCardSection :publication="publication" v-if="publication" />
			<LayoutTableMain :results="results" :loading="loading" :responsive="false" @goPage="goPage">
				<template #filters>
					<div class="input-group input-group-sm rounded-pill">
						<span class="input-group-text bg-white rounded-start-pill pe-0" id="basic-addon1"><i class="bi bi-search"></i></span>
						<input type="text" v-model="filters.search" class="form-control border-start-0 rounded-end-pill" placeholder="Buscar..." />
					</div>
				</template>
				<template #body>
					<UserTableRow v-for="data in results?.data" v-bind:key="data.id" :user="data" />
				</template>
			</LayoutTableMain>
		</main>
	</LayoutMain>
</template>

<script>
	import { ref, reactive, watch, onMounted } from 'vue';
	import { useRoute } from 'vue-router';
	import composableForm from '@/composables/form';
	import AccountPublicationRepository from '@/repositories/Account/PublicationRepository';
	import AccountStatRepository from '@/repositories/Account/StatRepository';

	export default {
		setup() {
			const { loading, catchError } = composableForm();
			const route = useRoute();
			const publication = ref(null);
			const results = ref({});
			const filters = reactive({
				search: null,
				orderBy: 'decisions.created_at',
				orderMethod: 'desc',
				take: 20,
				page: 1
			});

			const getPublication = () => {
				AccountPublicationRepository.show(route.params.id).then((response) => {
					publication.value = response.data;
				}).catch((e) => catchError(e, true));
			}

			const getResults = async () => {
				loading.value = true;

				await AccountStatRepository.superlikes(route.params.id, filters).then((response) => {
					results.value = response.data;
				}).catch((e) => {
					results.value = {};
					catchError(e, true);
				});

				loading.value = false;
			}

			const goPage = (page) => {
				filters.page = page;
				getResults();
			}

			const timeout = ref(null);
			watch(() => filters.search, () => {
				if(timeout.value) clearTimeout(timeout.value);
				timeout.value = setTimeout(() => goPage(1), 1000);
			});

			onMounted(() => {
				getPublication();
				getResults();
			});

			return { publication, loading, filters, results, goPage, getResults };
		}
	}
</script>