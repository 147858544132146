<template>
	<LayoutTableInfinite :results="results" :loading="loading" @goPage="goPage">
		<template #filters>
			<div class="dropdown flex-shrink-0">
				<button type="button" class="btn btn-sm btn-neutral rounded-pill" data-bs-toggle="dropdown" aria-expanded="false">
					<template v-if="!filters.time">Cualquier momento</template>
					<template v-else-if="filters.time == 'day'">Último día</template>
					<template v-else-if="filters.time == 'week'">Última semana</template>
				</button>
				<div class="dropdown-menu px-0 mt-2">
					<a href="javascript:void(0);" class="dropdown-item rounded-0" @click="setTime(null);">Cualquier momento</a>
					<a href="javascript:void(0);" class="dropdown-item rounded-0 fw-bold" @click="setTime('day');">Último día</a>
					<a href="javascript:void(0);" class="dropdown-item rounded-0 fw-bold" @click="setTime('week');">Última semana</a>
				</div>
			</div>
			<div class="dropdown flex-shrink-0" v-if="Object.keys(categories).length > 1">
				<button type="button" class="btn btn-sm btn-neutral rounded-pill" data-bs-toggle="dropdown" aria-expanded="false">
					<template v-if="!filters.category">Todas las categorías</template>
					<template v-else>Sólo <b>{{ categories[filters.category] }}</b></template>
				</button>
				<div class="dropdown-menu px-0 mt-2">
					<a href="javascript:void(0);" class="dropdown-item rounded-0 lh-xs" @click="setCategory(null);">
						Todas las categorías
					</a>
					<a href="javascript:void(0);" class="dropdown-item rounded-0 lh-xs" v-for="(category, index) in categories" v-bind:key="index" @click="setCategory(index);">
						Sólo <b>{{ category }}</b>
					</a>
				</div>
			</div>
		</template>
		<template #body>
			<AccountGroupPublicationTableRow v-for="data in results?.data" v-bind:key="data.id" :publication="data" @select="select" />
		</template>
	</LayoutTableInfinite>

	<PublicationModalShow ref="modalShow" :filtered="false" :publication="publication" />
</template>

<script>
	import { ref, reactive, watch, onMounted } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import _ from 'lodash';
	import composableForm from '@/composables/form';
	import AccountGroupPublicationRepository from '@/repositories/Account/GroupPublicationRepository';

	export default {
		props: {
			group: Object
		},
		setup(props) {
			const { loading, catchError } = composableForm();
			const results = ref({});
			const categories = ref([]);
			const filters = reactive({
				search: null,
				time: null,
				category: null,
				orderBy: 'created_at',
				orderMethod: 'desc',
				take: 20,
				page: 1
			});

			const modalShow = ref(null);
			const publication = ref(null);
			const select = (data) => {
				publication.value = data;
				modalShow.value.show();
			}

			const getResults = async () => {
				loading.value = true;

				await AccountGroupPublicationRepository.index(props.group.id, filters).then((response) => {
					let oldData = (results.value?.data) ? results.value.data : [];
					results.value = response.data;
					if(oldData.length > 0) results.value.data = _.concat(oldData, results.value.data);
				}).catch((e) => {
					catchError(e, true);
					router.push({ name: 'home' });
				});

				loading.value = false;
			}

			const getCategories = async () => {
				await AccountGroupPublicationRepository.categories(props.group.id).then((response) => {
					categories.value = response.data;
				});
			}

			const setTime = (time) => {
				if(filters.time == time) return;
				filters.time = time;
				refresh();
			}

			const setCategory = (category) => {
				if(filters.category == category) return;
				filters.category = category;
				refresh();
			}

			const refresh = () => {
				results.value = {};
				goPage(1);
			}

			const goPage = (page) => {
				filters.page = page;
				getResults();
			}

			const timeout = ref(null);
			watch(() => filters.search, () => {
				if(timeout.value) clearTimeout(timeout.value);
				timeout.value = setTimeout(() => goPage(1), 1000);
			});

			onMounted(() => {
				getResults();
				getCategories();
			});

			return { loading, filters, categories, results, setTime, setCategory, goPage, modalShow, publication, select };
		}
	}
</script>