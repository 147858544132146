<template>
	<tr>
		<td class="py-1">
			<div class="card rounded-4">
				<div class="card-body p-4">
					<div class="hstack gap-4 justify-content-between align-items-baseline lh-1 mb-2">
						<div class="vstack gap-1 position-relative">
							<div class="text-sm text-muted">{{ dateDiff }}</div>
							<a href="javascript:void(0)" class="h5 stretched-link lh-xs" @click="displayBody = !displayBody" v-html="title"></a>
						</div>
						<AccountGroupSearchTablePartPin :search="search" @reload="reload" />
					</div>
					<Transition name="fade-flash" mode="out-in">
						<div class="vstack gap-1 mt-1 mb-4" v-if="displayBody">
							<div v-for="(filter, index) in search.filters" :key="index">
								<AccountGroupSearchTablePartFilter :filter="filter" />
							</div>
							<AccountGroupSearchTablePartPlaces :places="search.places" v-if="search.places.length > 0" />
							<AccountGroupSearchTablePartDescription :description="search.description" v-if="search.description" />
						</div>
					</Transition>
					<div class="vstack">
						<div class="hstack gap-2 justify-content-between" v-for="user in search.users" :key="user.id">
							<UserCardSmall :user="user" :contact="true" />
							<UserCardPartContact :user="user" size="sm" />
						</div>
					</div>
				</div>
			</div>
		</td>
	</tr>
</template>

<script>
	import { ref, computed, inject } from 'vue';
	import _ from 'lodash';
	import moment from 'moment';
	import 'moment/locale/es';

	export default {
		emits: ['reload'],
		props: {
			search: Object
		},
		setup(props, { emit }) {
			const $env = inject('$env');
			const displayBody = ref(false);
			moment.locale('es');
			const dateDiff = moment(props.search.created_at).fromNow();
			const reload = () => emit('reload');
			const title = computed(() => {
				let numberFormat = new Intl.NumberFormat('es-ES'),
					array = [],
					title = '';

				_.forEach(props.search.filters, function(filter) {
					let arrayFilter = [];

					if(filter?.operation) arrayFilter.push($env.FIELDS.operations[filter.operation].description);
					if(filter?.category) arrayFilter.push($env.FIELDS.categories[filter.category].description);

					if(filter?.amount?.max) arrayFilter.push(`hasta ${numberFormat.format(filter.amount.max)}${(filter?.currency) ? ' '+filter.currency : ''}`);
					else if(filter?.amount?.min) arrayFilter.push(`desde ${numberFormat.format(filter.amount.min)}${(filter?.currency) ? ' '+filter.currency : ''}`);

					if(filter.extras?.rooms?.total) arrayFilter.push(`desde ${filter.extras.rooms.total} ambientes`);
					else if(filter.extras?.rooms?.bedrooms) arrayFilter.push(`desde ${filter.extras.rooms.bedrooms} dormitorios`);

					if(filter.extras?.areas?.total?.max) arrayFilter.push(`hasta ${filter.extras.areas.total.max} m2 totales`);
					else if(filter.extras?.areas?.total?.min) arrayFilter.push(`desde ${filter.extras.areas.total.min} m2 totales`);
					else if(filter.extras?.areas?.covered?.max) arrayFilter.push(`hasta ${filter.extras.areas.covered.max} m2 cubiertos`);
					else if(filter.extras?.areas?.covered?.min) arrayFilter.push(`desde ${filter.extras.areas.covered.min} m2 cubiertos`);

					array.push(arrayFilter.join(' '));
				});

				title = array.join(' o ');

				if(props.search.places.length > 0) title+= ` en ${_.map(props.search.places, 'name').join(', ')}`;

				return title;
			});

			return { displayBody, dateDiff, reload, title };
		}
	}
</script>