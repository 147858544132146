<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartImage :src="image.file_url" size="lg" />
		<LayoutTableCommonPartText :text="publication.title ?? 'Sin título'">
			<template #extras>
				<PublicationPartBadge :publication="publication" :scrollable="false" />
				<div class="d-flex" v-if="publication.priority"><PublicationPartPriority :priority="publication.priority" /></div>
			</template>
		</LayoutTableCommonPartText>
		<LayoutTableCommonPartSelect @select="select" />
	</LayoutTableCommonRow>
</template>

<script>
	import _ from 'lodash';

	export default {
		emits: ['select'],
		props: {
			publication: Object
		},
		setup(props, { emit }) {
			const image = _.head(_.filter(props.publication.medias, { type: 'image' }));
			const select = () => emit('select', props.publication);

			return { image, select };
		}
	}
</script>

<style scoped>
	.dropdown-menu {
		width: 180px;
	}
</style>