<template>
	<router-link :to="{ name: 'user.home', params: { slug: user.slug }}" class="vstack gap-4 text-center">
		<UserCommonImage :src="user.avatar_url" :size="imageSize" class="mx-auto" imageClass="border border-2 border-dark" v-if="user?.avatar_url" />
		<div class="text-break" :class="nameClass" :style="{ marginTop: (!user?.avatar_url) ? '70px' : 0 }">{{ user.name }}</div>
	</router-link>
</template>

<script>
	export default {
		props: {
			user: Object,
			nameClass: {
				type: String,
				default: 'h2 px-5'
			},
			imageSize: {
				type: String,
				default: 'xl'
			}
		}
	}
</script>