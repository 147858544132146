<template>
	<LayoutFormInputFilter :id="`step-${currentStep}`" title="Precio" subtitle="Opcional" :active="step == currentStep" :completed="currency != null || min != null || max != null" :selected="optionDescription" @empty="empty" @setStep="setStep">
		<div class="col-12">
			<select :value="currency" @input="setValueCurrency" class="form-select">
				<option value="">Seleccionar moneda</option>
				<option v-for="(name, index) in $env.FIELDS.currencies" v-bind:key="index" :value="index">{{ name }}</option>
			</select>
		</div>
		<div class="col-12 hstack gap-3">
			<VueNumberFormat :value="min" @update:value="setValueMin" class="form-control" placeholder="Desde" :options="{ precision: 0, acceptNegative: false, isInteger: true  }"></VueNumberFormat>
			<VueNumberFormat :value="max" @update:value="setValueMax" class="form-control" placeholder="Hasta" :options="{ precision: 0, acceptNegative: false, isInteger: true  }"></VueNumberFormat>
		</div>
	</LayoutFormInputFilter>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['update:currency', 'update:min', 'update:max', 'setStep'],
		props: {
			currency: String,
			min: Number,
			max: Number,
			step: String
		},
		setup(props, { emit }) {
			const currentStep = 'amount';

			const setValueCurrency = (event) => {
				let value = event.target.value;
				emit('update:currency', (value) ? String(value) : null);
			}
			const setValueMin = (value) => {
				emit('update:min', (value) ? Number(value) : null);
			}
			const setValueMax = (value) => {
				emit('update:max', (value) ? Number(value) : null);
			}
			const empty = () => {
				emit('update:currency', null);
				emit('update:min', null);
				emit('update:max', null);
			}
			const setStep = () => emit('setStep', currentStep);

			const optionDescription = computed(() => {
				let numberFormat = new Intl.NumberFormat('es-ES');

				if(props.min > 0 && props.max > 0) return `Entre ${numberFormat.format(props.min)} y ${numberFormat.format(props.max)}${(props.currency) ? ' '+props.currency : ''}`;
				else if(props.min > 0) return `Desde ${numberFormat.format(props.min)}${(props.currency) ? ' '+props.currency : ''}`;
				else if(props.max > 0) return `Hasta ${numberFormat.format(props.max)}${(props.currency) ? ' '+props.currency : ''}`;
				else if(props.currency) return props.currency;
				else return null;
			});

			return { currentStep, setValueCurrency, setValueMin, setValueMax, empty, setStep, optionDescription };
		}
	}
</script>