<template>
	<div class="card rounded-4">
		<div class="card-body p-4">
			<GroupCardMedium :group="group" :subtitle="subtitle" />
			<div class="hstack gap-2 justify-content-between mt-2">
				<div class="hstack gap-3">
					<router-link :to="{ name: 'account.group.search', params: { id: group.id }}" class="text-muted text-sm">Solicitudes</router-link>
					<div class="vr my-auto" style="height: 10px;"></div>
					<router-link :to="{ name: 'account.group.publication', params: { id: group.id }}" class="text-muted text-sm">Publicaciones</router-link>
				</div>
				<router-link :to="{ name: 'group.search.index', params: { id: group.id }}" class="btn btn-xs btn-neutral rounded-pill">Buscar</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import moment from 'moment';

	export default {
		props: {
			group: Object
		},
		setup(props, { emit }) {
			const subtitle = computed(() => {
				return `Desde el ${moment(props.group?.pivot.created_at).format('DD/MM/YYYY')}`;
			});

			return { subtitle };
		}
	}
</script>