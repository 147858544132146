<template>
	<LayoutOffcanvasStandard ref="offcanvas" :title="publication?.title" :subtitle="publication?.publicable?.name" id="publication-modal-detail">
		<template #body v-if="publication">
			<div class="vstack gap-5">
				<PublicationPartBadge :publication="publication" color="dark" />
				<PublicationPartPriorityComplete :priority="publication.priority" :description="publication.priority_description" v-if="publication.priority" />
				<LayoutFormInputGeneral :title="publication?.address?.title" v-if="publication.address">
					<CommonMapMap :center="{ lat: publication.address.geolocation.coordinates[0], lng: publication.address.geolocation.coordinates[1] }">
						<CommonMapMarker :position="{ lat: publication?.address.geolocation.coordinates[0], lng: publication?.address.geolocation.coordinates[1] }" />
					</CommonMapMap>
				</LayoutFormInputGeneral>
				<PublicationPartUsers :users="publication.users" />
				<PublicationPartStock :stock="publication.extras.stock" v-if="publication.extras.stock" />
				<PublicationPartExtras :extras="publication.extras" v-if="publication.extras" />
				<LayoutFormInputGeneral title="Descripción" v-if="publication.description">
					<div class="text-md lh-xs" v-html="publication.description"></div>
				</LayoutFormInputGeneral>
			</div>
		</template>
		<template #footer v-if="publication?.url">
			<a :href="publication.url" target="_blank" class="btn btn-dark rounded-pill w-100 w-sm-auto">Ver contenido</a>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import VueScrollTo from 'vue-scrollto';

	export default {
		props: {
			publication: Object
		},
		setup() {
			const offcanvas = ref(null);
			const show = () => offcanvas.value.offcanvas.show();

			onMounted(() => {
				document.getElementById('publication-modal-detail').addEventListener('hidden.bs.offcanvas', () => {
					VueScrollTo.scrollTo('#publication-modal-detail .offcanvas-body', 0, { container: '#publication-modal-detail .offcanvas-body' });
				});
			});

			return { offcanvas, show };
		}
	}
</script>