<template>
	<td :width="columnWidth" class="py-4 pe-0">
		<div class="position-relative avatar" :class="imageClass">
			<img :src="image" class="object-cover position-absolute top-50 start-50 translate-middle" />
		</div>
	</td>
</template>

<script>
	import { computed } from 'vue';
	import assetsImgIconsNoImage from '@/assets/img/icons/no-image.jpg';

	export default {
		props: {
			src: String,
			size: {
				type: String,
				default: 'xl'
			}
		},
		setup(props) {
			const image = computed(() => {
				if(props.src) return props.src;
				else return assetsImgIconsNoImage;
			});

			const imageClass = computed(() => {
				switch(props.size) {
					case 'xl':
						return `avatar-xl rounded-4`;
						break;
					case 'lg':
						return `avatar-lg rounded-3`;
						break;
					case 'md':
						return `avatar-md rounded-2`;
						break;
					case 'sm':
						return `avatar-sm rounded-1`;
						break;
				}
			});

			const columnWidth = computed(() => {
				switch(props.size) {
					case 'xl':
						return 85;
						break;
					case 'lg':
						return 75;
						break;
					case 'md':
						return 50;
						break;
					case 'sm':
						return 40;
						break;
				}
			});

			return { image, imageClass, columnWidth };
		}
	}
</script>

<style scoped>
	td {
		vertical-align: top;
	}
</style>