import { computed } from 'vue';
import store from '@/store';
import router from '@/router';
import { useRoute } from 'vue-router';

export default function() {
	const route = useRoute();
	const redirectNextInfo = computed(() => store.getters['redirect/next']);
	const redirectSearchInfo = computed(() => store.getters['redirect/search']);

	const hasRedirectBack = computed(() => {
		return !!window?.history?.state?.back;
	});

	const redirectBack = () => {
		if(!!window?.history?.state?.back) return router.go(-1);
		else return router.push({ name: 'home' });
	}

	const hasRedirectNext = computed(() => {
		return !!redirectNextInfo.value;
	});

	const redirectNext = () => {
		if(redirectNextInfo.value) return router.push(redirectNextInfo.value);
		else return router.push({ name: 'home' });
	}

	const hasRedirectSearch = computed(() => {
		return !!redirectSearchInfo.value;
	});

	const redirectSearch = () => {
		if(redirectSearchInfo.value) return router.push(redirectSearchInfo.value);
		else return router.push({ name: 'search.index' });
	}

	return { redirectNextInfo, hasRedirectBack, redirectBack, hasRedirectNext, redirectNext, hasRedirectSearch, redirectSearch };
}