<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartImage :src="group.logo_url" size="md" />
		<LayoutTableCommonPartText :text="group.name">
			<template #extras>
				<div class="text-sm text-muted hstack gap-1">
					<div class="text-dark fw-bold">{{ (group.pivot.admin) ? 'Administrador' : 'Miembro' }}</div>
					<div>·</div>
					<div>{{ (group.users_count == 1) ? `1 miembro` : `${group.users_count} miembros` }}</div>
				</div>
			</template>
		</LayoutTableCommonPartText>
		<LayoutTableCommonPartSelect @select="select" />
		<LayoutTableCommonPartMenu>
			<template #options>
				<router-link :to="{ name: 'account.group.show', params: { id: group.id }}" class="dropdown-item rounded-0 fw-bold">Detalle</router-link>
				<router-link :to="{ name: 'group.search.index', params: { id: group.id }}" class="dropdown-item rounded-0 fw-bold">Buscar</router-link>
				<router-link :to="{ name: 'account.group.search', params: { id: group.id }}" class="dropdown-item rounded-0 fw-bold">Solicitudes</router-link>
				<router-link :to="{ name: 'account.group.publication', params: { id: group.id }}" class="dropdown-item rounded-0 fw-bold">Publicaciones</router-link>
			</template>
		</LayoutTableCommonPartMenu>
	</LayoutTableCommonRow>
</template>

<script>
	import router from '@/router';

	export default {
		props: {
			group: Object
		},
		setup(props) {
			const select = () => router.push({ name: 'account.group.search', params: { id: props.group.id }});

			return { select };
		}
	}
</script>