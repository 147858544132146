<template>
	<td :class="class">
		<slot />
	</td>
</template>

<script>
	export default {
		props: {
			class: {
				type: String,
				default: 'py-0'
			}
		}
	}
</script>