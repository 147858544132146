<template>
	<LayoutClean class="vstack justify-content-center bg-primary">
		<div class="container-sm my-10">
			<div class="vstack gap-8">
				<div class="hstack align-items-center justify-content-between">
					<!-- Logo -->
					<router-link :to="{ name: 'home' }">
						<img src="@/assets/img/logo/complete-white.png" class="h-rem-10" alt="logo">
					</router-link>
					<div>
						<UserCardMenu :user="user" v-if="hasUser" />
						<UserCardMenuEmpty v-else />
					</div>
				</div>
				<div class="vstack gap-3" v-if="hasUser && (user.slug || user.groups.length > 0 || accounts.length > 0)">
					<HomeShortcuts v-if="hasUser" />
					<HomeGroup :groups="user.groups" v-if="user.groups.length > 0" />
					<UserCardHome :user="user" v-if="user.slug" />
					<HomeAccount :accounts="accounts" v-if="accounts.length > 0" />
				</div>
				<div class="card text-bg-white border-0 rounded-4 shadow-none" v-else>
					<div class="card-body vstack gap-2 p-6 text-center">
						<div class="ls-tight lh-xs text-md">Si te gusta nuestra plataforma o tienes alguna duda ¡no dudes en contactarnos!</div>
						<a :href="`mailto:${$env.CONTACT_EMAIL}`" class="link-primary fw-bold">{{ $env.CONTACT_EMAIL }}</a>
					</div>
				</div>
			</div>
		</div>
	</LayoutClean>
</template>

<script>
	import { ref, computed, onMounted, onUnmounted } from 'vue';
	import store from '@/store';
	import composableForm from '@/composables/form';
	import AuthRepository from '@/repositories/AuthRepository';

	export default {
		setup() {
			const { catchError } = composableForm();
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);
			const themeColor = document.querySelector('meta[name="theme-color"]').content;
			const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--x-primary');
			const accounts = ref([]);

			onMounted(async () => {
				document.querySelector('meta[name="theme-color"]').content = primaryColor;

				if(hasUser.value) {
					await AuthRepository.accounts().then((response) => {
						accounts.value = response.data;
					}).catch((e) => catchError(e, true));
				}
			});

			onUnmounted(() => {
				document.querySelector('meta[name="theme-color"]').content = themeColor;
			});

			return { hasUser, user, accounts };
		}
	}
</script>