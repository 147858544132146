<template>
	<div class="vstack">
		<div class="hstack gap-14 justify-content-between mb-5" v-if="title || $slots.filters">
			<h5 class="py-2" v-if="title">{{ title }}</h5>
			<div class="hstack gap-2 flex-fill flex-sm-grow-0" v-if="$slots.filters">
				<slot name="filters" />
			</div>
		</div>
		<div>
			<table class="table table-sm">
				<thead class="table-light" v-if="$slots.head">
					<slot name="thead" />
				</thead>
				<tbody v-if="$slots.body">
					<tr v-if="loading && (results?.data ?? []).length == 0">
						<td :colspan="columns" class="placeholder-glow">
							<span class="col-3 placeholder rounded-pill"></span>
						</td>
					</tr>
					<slot name="body" />
				</tbody>
			</table>
		</div>
		<div class="py-5 hstack gap-4">
			<button id="show-more" class="btn btn-sm btn-neutral rounded-pill" @click="goNext" :disabled="loading" v-show="results?.next_page_url">Cargar más</button>
			<span class="hstack gap-2 align-items-center text-muted text-sm lh-sm" v-if="loading">
				<div class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
				<span>Cargando resultados</span>
			</span>
			<span class="text-muted text-sm lh-sm" v-else-if="results.total == 0">No hay resultados</span>
			<span class="text-muted text-sm lh-sm" v-else>Mostrando <b>{{ results?.data.length }}</b> {{ (results.total == 1) ? 'resultado' : 'resultados' }}</span>
		</div>
	</div>
</template>

<script>
	import { onMounted } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['goPage'],
		props: {
			title: String,
			results: {
				type: Object,
				default: []
			},
			loading: Boolean,
			columns: Number
		},
		setup(props, { emit }) {
			const goNext = () => {
				emit('goPage', (props.results.current_page + 1));
			}

			onMounted(() => {
				const observer = new IntersectionObserver(
					(entries) => {
						_.forEach(entries, (entry) => {
							if(entry.isIntersecting && !props.loading && props.results.last_page > props.results.current_page) goNext();
						});
					}, { root: document, rootMargin: '-50px 0px', }
				);

				observer.observe(document.querySelector('#show-more'));
			});

			return { goNext };
		}
	}
</script>