<template>
	<div class="text-end">
		<router-link :to="{ name: 'account.publication.create' }" class="btn btn-neutral rounded-pill">
			<i class="bi bi-plus me-2"></i>
			Nueva publicación
		</router-link>
	</div>
</template>

<script>
	export default {
		props: {
			accounts: Array
		}
	}
</script>