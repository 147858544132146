<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection :text="search.title" :subtext="subtext" v-if="search" />
				<LayoutTextSectionSkeletor :text="true" :subtext="true" v-else />
			</template>
			<template #actions>
				<div class="hstack gap-2 justify-content-start justify-content-lg-end">
					<a href="javascript:void(0);" @click="modalOption?.show();" class="btn btn-sm btn-neutral rounded-pill">Opciones</a>
					<router-link :to="{ name: 'search.result', params: { uuid: route.params?.uuid }}" class="btn btn-sm btn-dark rounded-pill flex-shrink-0">Buscar</router-link>
				</div>
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-5 pb-10 pb-0 vstack gap-10">
			<LayoutTableInfinite :results="results" :loading="loading" @goPage="goPage">
				<template #filters>
					<div class="input-group input-group-sm rounded-pill">
						<span class="input-group-text bg-white rounded-start-pill pe-0" id="basic-addon1"><i class="bi bi-search"></i></span>
						<input type="text" v-model="filters.search" class="form-control border-start-0 rounded-end-pill" placeholder="Buscar..." />
					</div>
					<div class="dropdown flex-shrink-0">
						<button type="button" class="btn btn-sm btn-neutral rounded-pill" data-bs-toggle="dropdown" aria-expanded="false">
							<template v-if="filters.like">Gustan</template>
							<template v-else>No gustan</template>
						</button>
						<div class="dropdown-menu px-0 mt-2">
							<a href="javascript:void(0);" class="dropdown-item rounded-0 fw-bold" @click="setLike(true);">Gustan</a>
							<a href="javascript:void(0);" class="dropdown-item rounded-0 fw-bold" @click="setLike(false);">No gustan</a>
						</div>
					</div>
					<div class="dropdown flex-fill text-limit" v-if="(search?.users ?? []).length > 1">
						<a href="javascript:void(0);" class="btn btn-sm btn-neutral rounded-pill d-block text-truncate" data-bs-toggle="dropdown" aria-expanded="false">Por <b>{{ userSelected?.name }}</b></a>
						<div class="dropdown-menu px-0 mt-2">
							<a href="javascript:void(0);" class="dropdown-item rounded-0 lh-xs" v-for="user in search.users" v-bind:key="user.id" @click="setUser(user.id);">
								<b>{{ user.name }}</b><br />
								{{ user.email }}
							</a>
						</div>
					</div>
				</template>
				<template #body>
					<tr v-if="loading && !results">
						<td colspan="3" class="placeholder-glow">
							<span class="col-3 placeholder rounded-pill"></span>
						</td>
					</tr>
					<PublicationTableRow v-for="data in results?.data" v-bind:key="data.id" :publication="data" @select="select" />
				</template>
			</LayoutTableInfinite>
		</main>

		<SearchModalOption ref="modalOption" :search="search" :hidden="['list']" v-if="search" />
		<PublicationModalShow ref="modalShow" :filtered="true" :search="search" :filters="filters" :publication="publication" @success="refresh" />
	</LayoutMain>
</template>

<script>
	import { ref, reactive, computed, watch, onMounted } from 'vue';
	import { useRoute } from 'vue-router';
	import router from '@/router';
	import store from '@/store';
	import _ from 'lodash';
	import moment from 'moment';
	import 'moment/locale/es';
	import composableForm from '@/composables/form';
	import SearchRepository from '@/repositories/SearchRepository';

	export default {
		setup() {
			const { loading, catchError } = composableForm();
			const route = useRoute();
			const search = ref(null);
			const publication = ref(null);
			const modalOption = ref(null);
			const modalShow = ref(null);

			const results = ref({});
			const filters = reactive({
				search: null,
				user_id: (route.query?.user_id) ? Number(route.query?.user_id) : store.getters['auth/user'].id,
				like: true,
				orderBy: 'title',
				orderMethod: 'asc',
				take: 20,
				page: 1
			});

			const userSelected = computed(() => _.find(search.value?.users, { id: filters.user_id }));

			moment.locale('es');
			const dateDiff = (date) => moment(date).fromNow();
			const subtext = computed(() => {
				if(search.value?.user) return `En la cuenta <a href="${router.resolve({ name: 'user.search.index', params: { slug: search.value.user.slug }}).href}" class="link-dark fw-bold">${search.value.user.name}</a> ${dateDiff(search.value.created_at)}`;
				else if(search.value?.group) return `En el grupo <a href="${router.resolve({ name: 'group.search.index', params: { id: search.value.group.id }}).href}" class="link-dark fw-bold">${search.value.group.name}</a> ${dateDiff(search.value.created_at)}`;
				else return `Creada ${dateDiff(search.value.created_at)}`;
			});

			const setLike = (like) => {
				if(filters.like == like) return;
				filters.like = like;
				refresh();
			}

			const setUser = (user_id) => {
				if(filters.user_id == user_id) return;
				filters.user_id = user_id;
				refresh();
			}

			const refresh = () => {
				results.value = {};
				goPage(1);
			}

			const getResults = async () => {
				loading.value = true;

				await SearchRepository.filtereds(route.params.uuid, filters).then((response) => {
					let oldData = (results.value?.data) ? results.value.data : [];
					results.value = response.data;
					if(oldData.length > 0) results.value.data = _.concat(oldData, results.value.data);
				}).catch((e) => catchError(e, true));

				loading.value = false;
			}

			const goPage = (page) => {
				filters.page = page;
				getResults();
			}

			const select = (data) => {
				publication.value = data;
				modalShow.value.show();
			}

			const timeout = ref(null);
			watch(() => filters.search, () => {
				if(timeout.value) clearTimeout(timeout.value);
				timeout.value = setTimeout(() => {
					results.value = {};
					goPage(1);
				}, 1000);
			});

			onMounted(() => {
				SearchRepository.show(route.params.uuid, { type: 'complete' }).then((response) => {
					search.value = response.data;
				}).catch((e) => {
					search.value = {};
					catchError(e, true);
				});

				getResults();
			});

			return { route, loading, filters, userSelected, search, setLike, setUser, refresh, getResults, results, goPage, publication, modalOption, modalShow, select, dateDiff, subtext };
		}
	}
</script>